import Vue from 'vue'
import VueRouter from 'vue-router'
import tools from '@/tools/cookie'
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'StudentLogin',
    component: () => import('@/views/StudentLogin.vue'),
    beforeEnter: (to, from, next) => {
      if (tools.cookie.get('studentToken')) next('/Home');
      else next();
    }
  },
  {
    path: '/AdminLogin',
    name: 'AdminLogin',
    component: () => import('@/views/AdminLogin.vue'),
    beforeEnter: (to, from, next) => {
      if (tools.cookie.get('adminToken')) next('/AdminHome');
      else next();
    }
  },
  {
    path: '/Home',
    name: 'StudentHome',
    component: () => import('@/views/StudentHome.vue'),
    beforeEnter: (to, from, next) => {
      if (tools.cookie.get('studentToken')) next();
      else next('/');
    }
  },
  {
    path: '/Admin',
    name: 'AdminHome',
    redirect: '/Admin/School',
    component: () => import('@/views/AdminHome.vue'),
    beforeEnter: (to, from, next) => {
      if (tools.cookie.get('adminToken')) next();
      else next('/AdminLogin');
    },
    children: [
      {
        path: 'School',
        name: 'School',
        component: () => import ('@/views/School.vue'),
        beforeEnter: (to, from, next) => {
          if (store.getters.authenticationRole !== 'Editor') next();
          else next({ name: 'Worksheet'});  
        }
      },
      {
        path: 'Campus',
        name: 'Campus',
        component: () => import ('@/views/Campus.vue'),
        beforeEnter: (to, from, next) => {
          if (store.getters.authenticationRole !== 'Editor') next();
          else next({ name: 'Worksheet'});  
        }
      },
      {
        path: 'Class',
        name: 'Class',
        component: () => import ('@/views/Class.vue'),
        beforeEnter: (to, from, next) => {
          if (store.getters.authenticationRole !== 'Editor') next();
          else next({ name: 'Worksheet'});  
        }
      },
      {
        path: 'Branch',
        name: 'Branch',
        component: () => import ('@/views/Branch.vue'),
        beforeEnter: (to, from, next) => {
          if (store.getters.authenticationRole !== 'Editor') next();
          else next({ name: 'Worksheet'});  
        }
      },
      {
        path: 'Student',
        name: 'Student',
        component: () => import ('@/views/Student.vue'),
        beforeEnter: (to, from, next) => {
          if (store.getters.authenticationRole !== 'Editor') next();
          else next({ name: 'Worksheet'});  
        }
      },
      {
        path: 'Worksheet',
        name: 'Worksheet',
        component: () => import ('@/views/Worksheet.vue'),
      },
      {
        path: 'Teacher',
        name: 'Teacher',
        component: () => import ('@/views/Teacher.vue'),
        beforeEnter: (to, from, next) => {
          if (store.getters.authenticationRole !== 'Editor') next();
          else next({ name: 'Worksheet'});  
        }
      },
    ]
  },
  {
    path: '/Admin/*',
    component: () => import('@/views/AdminLogin')
  },
  {
    path: '*',
    component: () => import('@/views/StudentLogin')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
