import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeNavText: '1',
    authenticationRole: '',
  },
  getters: {
    activeNavText: state => state.activeNavText,
    authenticationRole: state => state.authenticationRole,
  },
  mutations: {
    setActiveNavText(state, payload) {
      state.activeNavText = payload;
    },
    setAuthenticationRole(state, payload) {
      state.authenticationRole = payload;
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
