import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { 
  Modal, 
  Icon, 
  Button, 
  Layout, 
  Menu, 
  Select, 
  Table, 
  Tag, 
  Popconfirm, 
  message, 
  Tabs, 
  Form, 
  Input, 
  Upload
} from 'ant-design-vue';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

Vue.use(Modal);
Vue.use(Icon);
Vue.use(Button);
Vue.use(Layout);
Vue.use(Menu);
Vue.use(Select);
Vue.use(Table);
Vue.use(Tag);
Vue.use(Popconfirm);
Vue.use(Tabs);
Vue.use(Form);
Vue.use(Input);
Vue.use(Upload);

Vue.config.productionTip = false
Vue.prototype.$message = message;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
